import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';

var intervalId;
class HomeRenderer extends Highway.Renderer{
    onEnter(){
        /*if(window.matchMedia("min-width:992px")){
            smooth = new SmoothScroll();
        }*/
        document.body.style.overflow = 'hidden';
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.querySelector('header').classList.remove('bgBlanc');
        document.getElementById('menuText').innerText = "MENU";
        intervalId = setInterval(sliderActivites, 4000);

    };
    onEnterCompleted(){

        const tl = new TimelineLite();
        //let div = document.querySelector('div[data-scroll]');
        tl.fromTo('main', 1.5, {x: '70%'}, {x: '0', ease:"power3.out"});
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"}, "-=1");
        let sliderActivitesContainer = document.querySelector('.slider-container');
        if(typeof sliderActivitesContainer !== "undefined" && sliderActivitesContainer !== null){
            sliderActivitesContainer.addEventListener('mouseenter', function(){
                //console.log('no slide');
                clearInterval(intervalId);
            });
            //sliderActivitesContainer.mouseleave(function(){
            sliderActivitesContainer.addEventListener('mouseleave', function(){
              //console.log('slide');
              intervalId = setInterval(sliderActivites, 4000);
            });
        }        
        
        setTimeout(function(){
            document.body.style.overflowX = 'hidden';
            document.body.style.overflowY = 'scroll';
            HomePage();
        }, 1000);
    };
    onLeave(){
        document.body.style.overflow = 'hidden';

        clearInterval(intervalId);
    };
}

export default HomeRenderer;
import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';

class ProjetsRenderer extends Highway.Renderer{

    onEnter(){
        document.querySelector('main').style.transform = 'none';
        document.getElementById('nav-secondaire-header').style.display = 'none';
        //document.querySelector('.mousey').style.display = 'none';
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.querySelector('header').classList.remove('bgBlanc');
        document.getElementById('menuText').innerText = "MENU";
    };
    onEnterCompleted(){
        const tl = new TimelineLite();tl
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"});
        tl.fromTo('div#projets', 0.5, {x: '10%'}, {x: '0', ease:"power3.out"}, "-=0.75");
        document.removeEventListener('keyup', clickUpDownProjets);
        //let projets = document.getElementById('projets');
        //projets.removeEventListener('touchstart', handleTouchStart, false);
        //projets.removeEventListener('touchmove', handleTouchMove, false);
        setTimeout(function(){
            sliderPageProjets();
        }, 1000);
    }
    onLeave(){
        updateProgress();
    }
    onLeaveCompleted(){
        document.getElementById('nav-secondaire-header').style.display = 'block';
        //document.querySelector('.mousey').style.display = 'block';
        document.removeEventListener('touchstart', handleTouchStart, false);
        document.removeEventListener('touchmove', handleTouchMove, false);
    };
}

export default ProjetsRenderer;
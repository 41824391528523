import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';


class LeftToRight extends Highway.Transition{
    in({from, to, done}){
        /*tl.fromTo('div#loader', 0.7, {left: '0'}, {left: '-100%', onComplete: function(){
            done();
        }});*/
        done();
    };
    out({from, done}){
        document.getElementById('nav-secondaire-header').classList.remove('fadeHeader');
        const tlLeftToRight = new TimelineLite();
        tlLeftToRight.fromTo('main', 1.5, {x: '0'}, {x: '-30%', ease:"power3.out", onComplete: function(){
            if(window.matchMedia('min-width:992px').matches){
                document.getElementById('menu-fullPage').classList.remove('open');
                document.getElementById('ham').classList.remove('activeHam');
                let menu = document.getElementById('menuText');
                if(typeof menu !== 'undefined' && menu !== null )
                    document.getElementById('menuText').innerText = "MENU";
                //document.body.classList.remove('fixBody');
                //console.log('scroll top');
            }          
            
            setTimeout(function(){
                window.scrollTo(0,0);
                document.getElementById('backToTop').classList.remove('run');
            },10);
            from.remove();
            done();
        }});
        tlLeftToRight.fromTo('div#loader', 1, {x: '100%'}, {x: '0', ease:"power3.out"}, "=-1.5");
        
        
    };
}

export default LeftToRight;
import Highway from '@dogstudio/highway';
//import {TimelineLite} from 'gsap/';


class ContextualMenuTransition extends Highway.Transition{
    in({from, to, done}){
        /*tl.fromTo('div#loader', 0.7, {left: '0'}, {left: '-100%', onComplete: function(){
            done();
        }});*/
        done();
    };
    out({from, done}){
        //tlMenuTransition.fromTo('#menuImg', 10, {x: '0'}, {x: '-56%', onComplete: function(){
            document.getElementById('menu-container').classList.add('active');
            document.getElementById('nav-secondaire-header').classList.remove('fadeHeader');
            let subMenu = [...document.getElementsByClassName('sub-menu')];
            subMenu.forEach(function(item){
                item.classList.add('active');
            });
            document.getElementById('menu-navigation-principale').classList.add('active');
            document.getElementById('menuButtonOpen').classList.add('active');
            let menuItems = [...document.getElementsByClassName('nav-links'), ...document.getElementsByClassName('espace-marche'), ...document.getElementsByClassName('link-coord')];
            menuItems.forEach(function(item){
                item.classList.add('active');
            });
            /*const tl = new TimelineLite();

            tl.fromTo('div#loader', 1.5, {x: '100%'}, {x: '0', ease:"power3.out", onComplete: function(){
                setTimeout(function(){
                    window.scrollTo(0,0);
                },10);
            }});*/


            setTimeout(function(){
                document.getElementById('menuButtonOpen').classList.remove('active');
                document.getElementById('ham').classList.remove('activeHam');
                document.getElementById('backToTop').classList.remove('run');
                if(typeof menu !== 'undefined' && menu !== null )
                    document.getElementById('menuText').innerText = "MENU";
                document.getElementById('menu-fullPage').classList.remove('open');
                document.getElementById('menu-container').classList.remove('active');
                document.getElementById('menu-navigation-principale').classList.remove('active');
                menuItems.forEach(function(item){
                    item.classList.remove('animateIn');
                    item.classList.remove('active');
                });     
                subMenu.forEach(function(item){
                    item.classList.remove('active');
                });
                window.scrollTo(0,0);
                from.remove();
                done();
            }, 1500);
            
            //document.getElementById('menu-fullPage').classList.remove('open');
        //}});
    };
}

export default ContextualMenuTransition;
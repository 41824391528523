import Highway from '@dogstudio/highway';
import LeftToRight from './transitions/LeftToRight';
import CustomRenderer from './renderers/customRenderer';
import ContactRenderer from './renderers/contactRenderer';
import HomeRenderer from './renderers/homeRenderer';
import ProjetsRenderer from './renderers/projetsRenderer';
import MapRenderer from './renderers/mapRenderer';
import ContextualMenuTransition from './transitions/ContextualMenuTransition';
import FicheProjetRenderer from './renderers/ficheProjetRenderer';
import NotreHistoireRenderer from './renderers/notreHistoireRenderer';
import RechercheRenderer from './renderers/rechercheRenderer';


const adminBar = document.querySelector('#wpadminbar');

if (!adminBar) {
    const H = new Highway.Core({
        renderers:{
            home: HomeRenderer,
            projets: ProjetsRenderer,
            ficheProjet: FicheProjetRenderer,
            map: MapRenderer,
            contact: ContactRenderer,
            recherche: RechercheRenderer,
            notre_histoire: NotreHistoireRenderer,
            espacePresse: CustomRenderer,
            custom: CustomRenderer
        },
        transitions: {
            default: LeftToRight,
            contextual: {
                menuTranstiton: ContextualMenuTransition
            }
        }
    });
}
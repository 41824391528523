import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';


class RechercheRenderer extends Highway.Renderer{

    onEnterCompleted(){
        const tl = new TimelineLite();
        tl.fromTo('main', 1.5, {x: '70%'}, {x: '0', ease:"power3.out"});
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"}, "-=1");
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.querySelector('header').classList.remove('bgBlanc');
        document.getElementById('menuText').innerText = "MENU";

        var input = document.getElementById("site-search");
        // Execute a function when the user releases a key on the keyboard
        input.addEventListener("keyup", function(event) {
          // Number 13 is the "Enter" key on the keyboard
          if (event.keyCode === 13) {
            event.preventDefault();
            rechercheInPage();
          }
        });
    };
    onLeave(){
      document.body.style.overflow = 'hidden';
    }
}

export default RechercheRenderer;
import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';


class ContactRenderer extends Highway.Renderer{
    onEnter(){
        document.body.style.overflow = 'hidden';
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.querySelector('header').classList.remove('bgBlanc');
        document.getElementById('menuText').innerText = "MENU";
    };
    onEnterCompleted(){
        const tl = new TimelineLite();
        tl.fromTo('main', 1.5, {x: '70%'}, {x: '0', ease:"power3.out"});
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"}, "-=1");
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'scroll';
        formContactInit();

    };

}

export default ContactRenderer;
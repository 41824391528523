import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';
//import Map from '../soreli/map';

class MapRenderer extends Highway.Renderer{
    onEnter(){
        document.body.style.overflow = "hidden";
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.getElementById('menuText').innerText = "MENU";
        
        let cursor = [...document.getElementsByClassName('cursor')];
        cursor.forEach(function(curs){
              curs.style.opacity = "0";
        });
        document.getElementById('nav-secondaire-header').style.display = 'none';
        document.querySelector('.logo-2').style.display = 'none';
    }
    onEnterCompleted(){
        const tl = new TimelineLite();

        tl.fromTo('main', 1.5, {x: '70%'}, {x: '0', ease:"power3.out"});
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"}, "-=1");
        //const carte = new Map();

        setTimeout(function(){
            startMap();
        }, 1000);
    }
    onLeave(){
        document.body.style.overflow = 'hidden';
    }
    onLeaveCompleted(){
        let cursor = [...document.getElementsByClassName('cursor')];
        cursor.forEach(function(curs){
              curs.style.opacity = "1";
        });
        document.getElementById('nav-secondaire-header').style.display = 'block';
        document.querySelector('.logo-2').style.display = 'block';
    };
}

export default MapRenderer;
import Highway from '@dogstudio/highway';
import {TimelineLite} from 'gsap/';

class FicheProjetRenderer extends Highway.Renderer{
    onEnter(){
        /*if(window.matchMedia("min-width:992px")){
            smooth = new SmoothScroll();
        }*/
        document.body.style.overflow = 'hidden';
        document.querySelector('html').classList.remove('fixMenu');
        document.body.classList.remove('fixMenu');
        document.querySelector('header').classList.remove('bgBlanc');
        document.getElementById('menuText').innerText = "MENU";

    };
    onEnterCompleted(){
        let divBackContainer = document.createElement('div');
        let headerContainer = document.getElementById('header__container');
        divBackContainer.classList.add('back-container');
        divBackContainer.setAttribute('id', 'back-container');
        divBackContainer.innerHTML = '<a href="/projets/" class="flexbox-center"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path class="chevron" d="M10301.334-3299.491l-5.245,5.246,5.245,5.246" transform="translate(-10283.917 3309.083)"/><g class="circle"><circle class="c" cx="15" cy="15" r="15"/><circle class="d" cx="15" cy="15" r="14.5"/></g></svg><p class="categ">Tous les projets</p></a>';
        headerContainer.append(divBackContainer);
        document.body.style.overflowX = 'hidden';
        document.body.style.overflowY = 'scroll';

        const tl = new TimelineLite();
        tl.fromTo('main', 1.5, {x: '70%'}, {x: '0', ease:"power3.out"});
        tl.fromTo('div#loader', 1, {x: '0'}, {x: '-100%', ease:"power3.out"}, "-=1");
        setTimeout(function(){
            initFicheProjet();
        }, 1000);
    };
    onLeave(){
        document.body.style.overflow = 'hidden';
        document.getElementById('back-container').remove();
    };
}

export default FicheProjetRenderer;